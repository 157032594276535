@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900');

body{
	font-family: 'Roboto';
	color: #4F4F4F;
	background-color: #FAFAFA;
}

.noselect {
  user-select: none; /* Standard */
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  &:focus{
  	outline: none;
  }
}

.center-box {
  min-height: calc(100vh - 40px);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
	font-family: 'Oswald';
}

.table {
	th, td{
		padding: 5px;
		vertical-align: middle;
	}
}

.text-primary{
	color: #24CAE2 !important;
}

.text-secondary{
 color: #4F4F4F !important;
}

.text-success{
	color: #3EA98B !important;
}

.text-danger{
	color: #F04057 !important;
}

.text-warning{
	color: #FFBF01 !important;
}

.text-info{
	color: #448BEB !important;
}

.text-light{
	color: #E0E0E0 !important;
}

.text-dark{
	color: #828282 !important;
}

.text-body{
	color: #333333 !important;
}

.text-muted{
	color: #E0E0E0 !important;
}

.text-white{
	color: #FFFFFF !important;
}

.text-black-50{
	color: #F2F2F2 !important;
}

.text-white-50{
	color: #FAFAFA !important;
}
.menu{
	.list-group-item{
		padding: 0;
		a{
			position: relative;
	    display: block;
	    padding: 0.75rem 1.25rem;
	    color: #4F4F4F;
	    background-color: #fff;
			&:hover, &.active{
				background-color: #F2F2F2;
			}
		}
	}
}

.svg-icon{
	display: inline-block;
	vertical-align: middle;
	&.icon-user{
		path{
			fill: #828282;
		}
	}
}

.form-group{
	margin-bottom: 10px;
	.css-yk16xz-control,
	.css-1pahdxg-control,
	.form-control{
		background-color: #FAFAFA;
		border: 1px solid #E0E0E0 !important;
		padding: 5px 10px;
    height: 38px;
    -webkit-tap-highlight-color: transparent;
    transition: all 0.25s ease-in-out 0s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
		
		&.error{
			border-color: #DE3A53 !important;
			background-color: rgba(255, 122, 137, 0.4); 
		}

		&:focus{
			border-color: #24CAE2 !important;
			background-color: #FAFAFA;
			box-shadow: none;
		}

    &.is-invalid {
      box-shadow: none;
      border-bottom: 3px solid #DE3A53;
      &:focus {
        border-bottom: 3px solid #DE3A53;
      }
    }
    &::-webkit-input-placeholder {
      color: #a1a6ae;
      font-weight: 400;
      font-size: 14px;
      font-style: unset;
    }
    &::-moz-placeholder {
      color: #a1a6ae;
      font-weight: 400;
      font-size: 14px;
      font-style: unset;
    }
    &:-ms-input-placeholder {
      color: #a1a6ae;
      font-weight: 400;
      font-size: 14px;
      font-style: unset;
    }
    &::-ms-input-placeholder {
      color: #a1a6ae;
      font-weight: 400;
      font-size: 14px;
      font-style: unset;
    }
	}
	.css-yk16xz-control,
	.css-1pahdxg-control{
		padding: 0;
		.css-g1d714-ValueContainer{
			padding-left: 10px;
		}
	}
	.error-text{
		color: #DE3A53;
	}

	select.form-control {
	  background-image: url(../images/select-chevron-down.svg);
	  background-repeat: no-repeat;
	  background-position: center right 9px;
	  padding: 5px 20px 5px 16px;
	}
	textarea.form-control {
		height: auto;
	}
}
.action-btn{
	.btn{
		margin: 0 2.5px;
	}
}


a {
  color: #448BEB;
  text-decoration: none;
  &:hover{
  	text-decoration: none;
  }
}

.navbar{
	padding: 1px 0;
	&.navbar-light{
		background-color: #fff;
		box-shadow: 0px 0px 10px rgba(5, 7, 19, 0.05);
		color: #828282;

		&:after {
	    display: block;
	    content: '';
	    position: absolute;
	    bottom: 0;
	    left: 50%;
	    width: 90%;
	    height: 20px;
	    transform: translate(-50%, 0);
	    box-shadow: 0px 15px 60px rgba(40, 74, 253, 0.1), 0px 10px 15px rgba(5, 7, 19, 0.03);
	    z-index: -1;
		}
	}
}

.top-page{
	padding-bottom: 20px;
	margin-bottom: 30px;
	border-bottom: 1px solid #BDBDBD;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}

.page-title{
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;
	letter-spacing: 0.065em;
	margin-bottom: 0;
	text-transform: uppercase;
	color: #4F4F4F;
	span{
		color: #BDBDBD;
		font-size: 14px;
	}
}

.blok-title{
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.065em;
	margin-bottom: 0;
	text-transform: uppercase;
	color: #4F4F4F;
}

ul.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  font-family: 'Roboto';
  font-weight: 500;
  margin-bottom: 0;
  font-size: 14px;
  li{
  	margin: 0 2.5px;
  	position: relative;
  	padding-right: 10px;
  	a{
			color: #4F4F4F;
		}
  	&:after{
  		content: '/';
	    right: 0;
	    position: absolute;
  	}
  	&.current{
  		padding-right: 0;
  		span{
				color: #BDBDBD;
			}
			&:after{
				content:'';
			}
  	}
  }
}

.btn {
  min-width: 140px;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 9.5px 28px;
  font-size: 13px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  outline: none !important;
  
  &.btn-primary {
    color: #fff;
    background-color: #24CAE2;
    border-color: #24CAE2;
	  &.btn-primary:not(:disabled):not(.disabled):active,
	  &.btn-primary:not(:disabled):not(.disabled).active,
	  &.show > .btn-primary.dropdown-toggle,
	  &:focus,
  	&:hover {
	    color: #fff;
	    background-color: #1db0c4;
	    border-color: #1db0c4;
		}
	}
	&:focus{
	  box-shadow: none;
	}

	&.btn-warning{
		color: #fff;
	}

	&.btn-outline-primary{
		color: #24CAE2;
    border-color: #24CAE2;
	  &.btn-outline-primary:not(:disabled):not(.disabled):active,
	  &.btn-outline-primary:not(:disabled):not(.disabled).active,
	  &.show > .btn-outline-primary.dropdown-toggle,
	  &:focus,
  	&:hover {
  		color: #fff;
  		background-color: #24CAE2;
    	border-color: #24CAE2;
  	}
	}
	&.btn-sm{
		min-width: 120px;
		padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
	}
}

.button {
  box-sizing: border-box;
  background: none;
  border: 2px solid transparent;
  display: inline-block;
  text-transform: uppercase;
  font: 500 13px/1 'Roboto', Helvetica, Arial, sans-serif;
  border-radius: 48px;
  padding: 9.5px 28px;
  user-select: none;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }

  &__txt {
    &:not(:first-child) {
      margin-left: 0.35em;
    }
  }
}

.button_type_primary {
  background: #24CAE2;
  color: #fff;
  box-shadow: 0 10px 15px rgba(36, 202, 226, 0.25);
  transition: background-color 0.3s;
  will-change: background-color;

  &:hover {
  	color: #fff;
    background: #1db0c4;
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 2px #becef8, 0 -2px #becef8, 2px 0 #becef8, -2px 0 #becef8;
  }

  &:disabled {
    background: #BDBDBD;
    color: #828282;
    box-shadow: none;
  }
}

.close{
	margin-left: 0; 
	position: relative;
	z-index: 200;
	&:focus, &:visited{
		outline: none;
	}
	img{
		width: 24px;
	}
}

.topLogo {
	display: block;
	position: relative;
  text-align: center;
  padding: 10px 25px;
  .icon-logo path{
  	fill: #24CAE2;
  }
}

.menusidebar-mobile {
  color: #c3c8cf;
  width: 250px;
  height: 100vh;
  background-color: #2A3142;
  transition: all 0.3s ease-in-out;
	box-shadow: 0px 15px 60px rgba(40, 74, 253, 0.1), 0px 10px 15px rgba(5, 7, 19, 0.03);
  z-index: 10;

 //  &:after {
 //    display: block;
 //    content: '';
 //    position: absolute;
 //    right: 0;
 //    top: 50%;
 //    width: 20px;
 //    height: 90%;
 //    transform: translate(0, -50%);
 //    box-shadow: 0px 15px 60px rgba(40, 74, 253, 0.1), 0px 10px 15px rgba(5, 7, 19, 0.03);
 //    z-index: -1;
	// }
	.main-menu{
		overflow-y: scroll;
		overflow-x: hidden;
		li.menu-title {
		  padding: 12px 10px!important;
		  letter-spacing: .05em;
		  pointer-events: none;
		  cursor: default;
		  font-size: 11px;
		  text-transform: uppercase;
		  color: #7f8387;
		  font-weight: 600;
		}
		li.menu-items{
			display: block;
    	width: 100%;
    	a.menu-item {
			  display: block;
			  padding: .625rem 1.5rem;
			  color: #A6B0CF;
			  position: relative;
			  font-size: 13px;
			  -webkit-transition: all .4s;
			  transition: all .4s;
			  &.active,
			  &:hover{
			  	color: #fff;
			  	background-color: #44567c;
			  	text-decoration: none;
			  }
			}
		}
	}
}

.card {
  background: #fff;
  box-shadow: 0px 0px 15px rgba(5, 7, 19, 0.02);
  padding: 0;
  border: none;
  position: relative;
  // z-index: 10;

  &:after{
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 90%;
    height: 30px;
    transform: translate(-50%, 0);
  	box-shadow: 0px 15px 60px rgba(40, 74, 253, 0.1), 0px 10px 15px rgba(5, 7, 19, 0.03);
    z-index: -1;
  }

  .card-header{
  	background-color: #E0E0E0;
  	font-family: 'Oswald';
  	font-weight: 500;
  	text-transform: uppercase;
  	color: #4F4F4F;
  	letter-spacing: 0.065em;
  	.arrow{
  		border: none;
  		background-color: transparent;
  		color: #BDBDBD;
  		height: 32px;
  		// width: 32px;
  		border-left: 2px solid #BDBDBD;
    	padding-left: 16px;
    	i{
    		transition: all .4s;
    		transform: rotate(0deg);
    	}
    	&.show{
    		i{
    			transform: rotate(180deg);
    		}
    	}
  	}
  }

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 24px;
	}
}

#root{
	overflow: hidden;
}

.closeMenu{
	left: 0;
	position: relative;
	transition: all 0.2s ease-in-out;
}

.openMenu{
	left: 250px;
	position: relative;
	transition: all 0.2s ease-in-out;
}

.card.filter{
	.card-header{
		padding: 0.25rem 1.15rem;
	}
	.card-body{
		padding: 15px 24px;
		.form-group{
			margin-bottom: 10px;
			.form-control{
				padding: 5px 10px;
				height: 32px;
				font-size: 14px;
			}
		}
	}
}

.btn-nextprev{
	border-radius: 5px;
	background-color: #fff;
	border: 1px solid #E0E0E0;
	&.disabled,
	&:focus,
	&:hover{
		background-color: #E0E0E0;
		color: #BDBDBD;
		opacity: 1;
	}
}
.pagination{
	.page-item{
		&.active{
			.page-link{
				background-color: #24CAE2;
				border-color: #24CAE2;
				color: #fff;
			}
		}
		.page-link{
			border: 1px solid #E0E0E0;
			background-color: #fff;
			line-height: 1;
			padding: 0.15rem 0.4rem;
			color: #4F4F4F;
		}
	}
}
.modals{
	&__overlay{
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0, 0, 0, 0.35);
	}
	&__header{
		display: block;
		margin: -5px -15px 0 -15px;
		padding-bottom: 15px;
		padding-left: 15px;
		padding-right: 15px;
		border-bottom: 1px solid #E4E6EE;
		h2{
			font-size: 18px;
			margin-bottom: 0;
		}
	}
	&__content{
		position: absolute;
	  top: 50%;
	  left: 50%;
	  right: auto;
	  bottom: auto;
	  border: 1px solid rgb(204, 204, 204);
	  background: rgb(255, 255, 255);
	  overflow: auto;
	  border-radius: 4px;
	  outline: none;
	  padding: 15px;
	  margin-right: -50%;
	  transform: translate(-50%, -50%);
	  
	  .form-group{
	  	margin-bottom: 10px;
	  	.form-control{
	  		height: 35px;
	  		padding: 5px 10px;
	  	}
	  	.css-yk16xz-control,
	  	.css-1pahdxg-control{
	  		height: 35px;
	  	}
	  }

	  .action-btn{
	  	padding-top: 10px;
	  	padding-left: 15px;
	  	padding-right: 15px;
	  	border-top: 1px solid #E4E6EE;
	  	margin: 0 -15px;
	  	text-align: center;
	  }

	  .page-title{
	  	text-transform: unset;
	  	letter-spacing: 0.025em;
	  }

	  .icon-sad{
	  	path{
	  		fill: #24CAE2;
	  	}
	  }
	}
	&__action{
		border-top: 1px solid #e4e6ee;
		padding-top: 1.25rem;
		margin: 20px -20px 0 -20px;
		text-align: center;
	}
}