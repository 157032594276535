.card-dash {
	.blok-u {
		margin-bottom: 27px;
		padding-bottom: 27px;
		border-bottom: 2px solid #bdbdbd;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.f-name {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.svg-icon {
				margin-right: 15px;
				&.icon-penjualan {
					path {
						fill: #24cae2;
					}
				}
				&.icon-pembelian {
					path {
						fill: #3ea98b;
					}
				}
			}
			h2 {
				font-weight: 500;
				font-size: 24px;
				margin-bottom: 5px;
				text-transform: uppercase;
			}
			p {
				margin-bottom: 0;
			}
		}
		.total {
			font-size: 24px;
		}
	}

	.blok-summary {
		ul {
			margin-bottom: 0;
			li {
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	sup {
		font-size: 12px;
		top: -0.8em;
	}
	&__condition {
		min-height: 228px;
		display: flex;
		margin: -24px;
	}
	&__summary {
		flex: 1 1 0%;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	&__content {
		margin-top: 35px;
		text-align: center;
		h2 {
			font-size: 14px;
			margin-bottom: 15px;
			font-family: 'Roboto';
			font-weight: 500;
		}
		.svg-icon {
			margin-bottom: 15px;
			&.icon-laba {
				path {
					fill: #24cae2;
				}
			}
			&.icon-neraca {
				path,
				rect,
				ellipse {
					fill: #448beb;
				}
				.stroke {
					stroke: #448beb;
					fill: transparent;
				}
			}
		}
	}
	&__amount {
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 19px;
		line-height: 22px;
	}
	&__text {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-align: center;
		color: #828282;
		a {
			color: #828282;
		}
	}
	&__summary-right {
		flex: 1 1 0%;
		border-right: 0.5px solid rgb(209, 216, 224);

		.content {
			height: 50%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			padding: 0 24px;
			.svg-icon {
				margin-right: 18px;
			}
			&-minus {
				border-top: 0.5px solid rgb(209, 216, 224);
				.icon-laba-minus path {
					fill: #f04057;
				}

				.icon-neraca-minus {
					path,
					rect,
					ellipse {
						fill: #f04057;
					}
					.stroke {
						stroke: #f04057;
						fill: transparent;
					}
				}

				.content-text {
					color: #f04057;
				}
			}
			&-plus {
				.icon-laba-plus path {
					fill: #1f967a;
				}

				.icon-neraca-plus {
					path,
					rect,
					ellipse {
						fill: #1f967a;
					}
					.stroke {
						stroke: #1f967a;
						fill: transparent;
					}
				}

				.content-text {
					color: #1f967a;
				}
			}
			&-items {
				margin-top: 10px;
				text-align: center;
			}
			&-amount {
				font-weight: 500;
				font-size: 24px;
				color: #4f4f4f;
				margin-bottom: 4px;
			}
			&-text {
				margin-bottom: 0;
			}
		}
	}
	.recharts-wrapper {
		width: 100% !important;
		.recharts-legend-item-text {
			font-size: 11px;
			font-weight: 400;
		}
	}
	.txt-amount {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
		align-items: center;
		margin-bottom: 5px;
		color: #4f4f4f;
		overflow: hidden;
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.label-progress {
		margin-bottom: 7px;
		p {
			margin-bottom: 0;
			font-weight: 500;
			font-size: 13px;
		}
	}
	.progress {
		margin-bottom: 10px;
		height: 10px;
		border-radius: 0;
		.progress-bar {
			background-color: #24cae2;
		}
	}
}
