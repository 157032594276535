.title-profile{
	font-family: 'Oswald';
	font-weight: 500;
}
.bg-comeback {
  display: block;
  position: relative;
  text-align: center;
  margin: 2rem auto -26px auto;
  /* min-height: 80px; */
  z-index: 11;
  width: 146px;
  background-color: #fff;
  padding: 8px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.closeBack {
  display: block;
  position: absolute;
  right: 1px;
  top: -11px;
  width: 14px;
  height: 14px;
}
.card{
	&.auths {
	  border: none;
	  box-shadow: 0px 0px 15px rgba(5, 7, 19, 0.04);
	  margin-bottom: 28px;
	  padding: 0;
	  z-index: unset;

	  &:after {
	    display: block;
	    content: '';
	    position: absolute;
	    bottom: 0;
	    left: 50%;
	    width: 90%;
	    height: 20px;
	    transform: translate(-50%, 0);
	    box-shadow: 0px 15px 60px rgba(40, 74, 253, 0.15), 0px 10px 15px rgba(5, 7, 19, 0.05);
	    z-index: -1;
		}
	  .card-header {
	    background-color: #ffffff;
	    border-color: #e1e2e8;
	    padding: 60px 32px;
	    font-size: 13px;
	    font-family: 'Oswald';
	    font-weight: 700;
	    line-height: 18px;
	    text-transform: uppercase;
		}
		.card-body {
	    padding: 1.25rem 45px;
		}
		.action-btn {
	    display: block;
	    position: relative;
	    text-align: center;
	    margin: 0 -45px 0 -45px;
	    border-top: 1px solid #e4e6ee;
	    padding-top: 1.25rem;
	    .btn{
	    	margin: 0 2.5px;
	    }
		}
	}
}