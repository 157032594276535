.user-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    display: none;
  }

  &__info-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 58px;
    cursor: pointer;
    i {
		  color: #828282;
      transition: transform 0.3s;
		}
    .svg-icon_chevron {
      transform: translateY(-10%) rotate(0);
      transition: transform 0.3s;
      will-change: transform;
    }

    &.active {
    	i{
        transform: translateY(-10%) rotate(-180deg);
        transition: transform 0.4s;
        will-change: transform;
    	}
      .svg-icon_chevron {
        transform: translateY(-10%) rotate(-180deg);
        will-change: transform;
      }
    }
  }

  &__name {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    color: #828282;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
  }

  &__user-icon {
  }

  &__arrow {
    display: inline-block;
    margin-left: 10px;
    transform-origin: center;
    transition: transform 0.25s ease-in-out;

    // .user-menu__info-box:focus & {
    //   transform: rotateZ(-180deg);
    // }
  }

  &__menu {
    position: absolute;
    top: 68px;
    right: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    box-shadow: 0px 10px 25px rgba(40, 74, 253, 0.05),
      0px 5px 10px rgba(5, 7, 19, 0.03);
    opacity: 0;
    transition: transform 0.25s cubic-bezier(0, 1.11, 1, 1.22),
      opacity 0.25s ease-in-out;
    pointer-events: none;
    transform-origin: top center;
    transform: perspective(200px) rotateX(-90deg);

    &_active {
      pointer-events: all;
      opacity: 1;
      width: 120px;
      transform: perspective(600px) rotateX(0);
      text-align: left;
      &:after {
        content: '';
        width: 0;
        height: 0;
        top: -10px;
        right: 32px;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #ffffff;
      }
    }
  }

  &__menu_active {
    position: absolute;
    top: 70px;
    right: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(49, 45, 249, 0.1),
      0px 2px 10px rgba(5, 7, 19, 0.05);
    transition: transform 0.25s cubic-bezier(0, 1.11, 1, 1.22),
      opacity 0.25s ease-in-out;
    transform-origin: top center;
    pointer-events: all;
    opacity: 1;
    transform: perspective(600px) rotateX(0);
    z-index: 101;
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 100;
  }

  &__item {
    &:first-child {
      border-bottom: 2px solid #e4e6ee;
      margin-bottom: 15px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .user-menu__link {
        padding: 15px;
      }
    }
    &:last-child {
      margin-bottom: 15px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &__link {
    display: block;
    padding: 5px 15px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;
    color: #828282;
    transition: background-color 0.2s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      color: #828282;
      background-color: #fafcfe;
    }

    &_accented {
      color: #f15038;

      &:hover,
      &:active,
      &:focus {
        color: #f15038;
      }
    }
  }
}

.header__user-menu .button {
    width: 130px;
    text-align: center;
}